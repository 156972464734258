<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-input-power"/>
      {{ $t('forms.transl104')}}
      {{ $v.form.email.$model }} - {{ $v.form.name.$model }} {{ $v.form.surname.$model }}
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12">
          <CForm enctype="multipart/form-data">
            <CRow>
              <CCol md="12">
                <CTextarea
                  :label="$i18n.translate('forms.transl108')"
                  :placeholder="$i18n.translate('forms.transl109')"
                  rows="9"
                  :isValid="checkIfValid('description')"
                  v-model.trim="$v.form.description.$model"
                  invalidFeedback="To pole jest wymagane"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <br/><br/>
                <small>{{ $t('forms.transl106')}}</small><br/><br/>
                <input type="file" id="otherFiles" class="otherFiles" ref="file" name="otherFiles" accept="image/*"
                       required>
                <br/><br/><br/>
              </CCol>
            </CRow>

            <CButton
              color="primary"
              :disabled="$v.form.$invalid"
              @click="submit"
            >
              {{ $i18n.translate('forms.transl52') }}
            </CButton>
            <CButton
  class="ml-1"
  color="success"
  @click="goBack"
>
  {{ $i18n.translate('forms.transl53') }}
</CButton>

          </CForm>
          <br/>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import CKEditor from 'ckeditor4-vue';
import Swal from "sweetalert2";
import {validationMixin} from "vuelidate"
import {required, minLength, email, sameAs, helpers} from "vuelidate/lib/validators"

export default {
  data() {
    return {
      form: {
        name: null,
        surname: null,
        description: null,
      },
      statuses: [],
    }
  },
  computed: {
    formString() {
      return JSON.stringify(this.form, null, 4)
    },
    isValid() {
      return !this.$v.form.$invalid
    },
    isDirty() {
      return this.$v.form.$anyDirty
    },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {},
      surname: {},
      email: {},
      description: {
        required,
        minLength: minLength(3),
      },
    }
  },
  methods: {
    goBack() {
      this.$router.replace({path: '/drivers/' + this.$route.params.id + '/edit'})
    },
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    },
    store() {
      let self = this;
      let formData = new FormData();

      formData.append('otherFiles', this.$refs.file.files['0']);
      formData.append('description', self.form.description);
      console.log(formData.entries());

      axios.post(this.$apiAdress + '/api/driver-files/save-driver-file/' + self.$route.params.id + '?token=' + localStorage.getItem("api_token")+ '&s_lng=' + self.$i18n.locale(), formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then(function (response) {
          if (response.data.status == 'success') {
            Swal.fire(
              self.$i18n.translate('forms.transl13'),
self.$i18n.translate('forms.transl62'),
              'success'
            ).then(function () {
              self.$router.push('/drivers/' + self.$route.params.id + '/edit');
            });
          } else {
            Swal.fire(
              self.$i18n.translate('forms.transl64'),
              response,
              'error'
            )
          }
        })
        .catch(function (error) {
          if (error.response.data.message != '') {
            let errorDetails = ""
            for (let key in error.response.data.errors) {
              errorDetails += `${error.response.data.errors[key]}<br/>`
            }
            Swal.fire(
              self.$i18n.translate('forms.transl64'),
              errorDetails,
              'error'
            )
            window.scrollTo({top: 0});
          } else {
            Swal.fire(
              self.$i18n.translate('forms.transl64'),
              error,
              'error'
            )
            self.$router.push({path: 'login'});
          }
        });

    },

    submit() {
      this.validate();
      if (!this.$v.form.$invalid) {
        this.store();
      }
    },

    validate() {
      this.$v.$touch()
    },

  },
  created: function () {
    let self = this;
    axios.get(this.$apiAdress + '/api/drivers/' + self.$route.params.id + '/edit?token=' + localStorage.getItem("api_token"))
      .then(function (response) {
        self.form.email = response.data.record.email;
        self.form.name = response.data.record.name;
        self.form.surname = response.data.record.surname;
        self.validate();
      }).catch(function (error) {
      console.log(error);
      self.$router.push({path: '/login'});
    });
  }
}
</script>
